import { useState } from 'react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import {
    DELIVERABLE_FILTERING_PROPERTIES,
    PROPERTY_FILTER_I18NSTRING,
} from '../../reusable/UseCollectionUtil'
import EmptyState from '../../reusable/EmptyState'
import {
    Button,
    ButtonDropdown,
    Header,
    Pagination,
    PropertyFilter,
    Select,
    SpaceBetween,
    Table,
} from '@amzn/awsui-components-react'
import { getMatchesCountText } from '../../reusable/Utils'
import ExportTable from '../../reusable/ExportTable'
import useLocalDeliverableStore from './LocalDeliverableStore'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from '../../../../context'
import useStore from '../../../Store'
import { getDeliverableExportVisibleColumns } from './DeliverableAttributes'

const DELIVERABLE_NAME_INDEX = 5

interface DeliverableListProps {
    programType: string
    localPrograms: any[]
    programMetadata: any
    canEdit: boolean
    localSelectedPlan: any
    canEstimate: boolean
    programName: string
    deliverables: any[]
    isLoading: boolean
    columns: any[]
    visibleColumns: any[]
    onCreate: () => void
    onEdit: () => void
    onDelete: () => void
    onCopy: () => void
    selectedDelivs: any[]
    onSelectedDelivsChange: (e: any) => void
    loadSelect: (target: any, itemType: string) => void
    selectedRevision: any
    onSelectedRevisionChange: (e: any) => void
    selectedRevisionOptions: any[]
    currentPath: string
    isRevisionLocked: boolean
}

const DeliverableList = ({
    programType,
    localPrograms,
    programMetadata,
    canEdit,
    localSelectedPlan,
    canEstimate,
    programName,
    deliverables,
    isLoading,
    columns,
    visibleColumns,
    onCreate,
    onEdit,
    onDelete,
    onCopy,
    selectedDelivs,
    onSelectedDelivsChange,
    loadSelect,
    selectedRevision,
    onSelectedRevisionChange,
    selectedRevisionOptions,
    currentPath,
    isRevisionLocked,
}: DeliverableListProps) => {
    const appContext = useAppContext()
    const userProps = appContext.userProps
    const canAdmin = useStore((state) => state.canAdmin)
    const userRoles = useStore((state) => state.userRoles)
    const planId = localSelectedPlan.value?.plan_id ?? ''
    const getIsStl = () => {
        for (const role of userRoles) {
            if (role.name === 'STL') {
                return true
            }
        }
        return false
    }
    const isEditActionForStl = () => {
        if (!isStlNotAdmin || !programMetadata.stl_alias) {
            return true
        }
        return (
            programMetadata.stl_alias.includes(userProps.userAlias) ||
            (programMetadata?.registered_users || []).includes(userProps.userAlias)
        )
    }

    const isStlNotAdmin = getIsStl() && !canAdmin
    const userGroups = useStore((state) => state.userGroups)
    const isStlEditable = isEditActionForStl()
    const isGlobalDeliverablesPage = programType === 'global'
    const [preferences] = useState({ pageSize: 20 })
    const setHcSelectedDeliverables = useLocalDeliverableStore(
        (state) => state.setHcSelectedDeliverables,
    )
    const navigate = useNavigate()
    const {
        items,
        allPageItems,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
        actions,
    } = useCollection(deliverables, {
        propertyFiltering: {
            filteringProperties: DELIVERABLE_FILTERING_PROPERTIES,
            empty: (
                <EmptyState
                    title={`No deliverables under ${programName}.`}
                    subtitle='No deliverables to display.'
                    action={
                        programMetadata?.is_active &&
                        !isGlobalDeliverablesPage &&
                        (canAdmin || isStlEditable) ? (
                            <Button
                                onClick={onCreate}
                                disabled={
                                    isRevisionLocked ||
                                    isLoading ||
                                    !programMetadata?.is_active ||
                                    (isStlNotAdmin && !isStlEditable)
                                }
                            >
                                Create a new deliverable
                            </Button>
                        ) : (
                            <></>
                        )
                    }
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: {
                sortingColumn: columns[DELIVERABLE_NAME_INDEX],
            },
        },
        selection: {},
    })
    // TODO: remove handler once new hc estimate workflow is accepted in production
    // const onEstimateClick = () => {
    //     navigate(`${currentPath}/estimate-headcount`, {
    //         state: {
    //             programType: programType,
    //             localPrograms: localPrograms.join(','),
    //         },
    //     })
    // }
    return (
        <Table
            {...collectionProps}
            selectionType={canEdit || canEstimate ? 'multi' : undefined}
            selectedItems={selectedDelivs}
            onSelectionChange={({ detail }) => {
                onSelectedDelivsChange(detail.selectedItems)
                setHcSelectedDeliverables(detail.selectedItems)
            }}
            columnDefinitions={columns}
            items={items}
            loading={isLoading}
            loadingText='Loading Deliverables'
            wrapLines
            visibleColumns={visibleColumns}
            pagination={<Pagination {...paginationProps} />}
            filter={
                <SpaceBetween direction={'horizontal'} size={'xxl'}>
                    <PropertyFilter
                        i18nStrings={PROPERTY_FILTER_I18NSTRING}
                        {...propertyFilterProps}
                        countText={getMatchesCountText(filteredItemsCount)}
                    />
                    {!isGlobalDeliverablesPage && (
                        <SpaceBetween size={'xs'} direction={'horizontal'}>
                            <Select
                                placeholder={'Selected Plan'}
                                selectedOption={localSelectedPlan}
                                options={[]}
                                disabled={true}
                            />
                            <Select
                                placeholder={'Selected Revision'}
                                selectedOption={selectedRevision}
                                onChange={({ detail }) => {
                                    const option = detail.selectedOption
                                    onSelectedRevisionChange(option)
                                    onSelectedDelivsChange([])
                                    navigate(
                                        `/plan/${planId}/revision/${
                                            detail.selectedOption.value
                                        }/program/${
                                            programMetadata?.program_id ?? ''
                                        }?type=${programType}`,
                                    )
                                }}
                                options={selectedRevisionOptions}
                                disabled={isLoading}
                            />
                        </SpaceBetween>
                    )}
                </SpaceBetween>
            }
            header={
                <Header
                    variant='h2'
                    actions={
                        <SpaceBetween size='s' direction='horizontal'>
                            {!isGlobalDeliverablesPage &&
                                canEdit &&
                                !(isStlNotAdmin && !isStlEditable) && (
                                    <Button
                                        variant='primary'
                                        onClick={onCreate}
                                        disabled={
                                            isRevisionLocked ||
                                            isLoading ||
                                            !programMetadata?.is_active ||
                                            (isStlNotAdmin && !isStlEditable)
                                        }
                                    >
                                        New Deliverable
                                    </Button>
                                )}
                            {/* TODO: remove HC estimate button leading to old hc estimate page once new hc estimates page is accepted in production */}
                            {/*{!isGlobalDeliverablesPage && (*/}
                            {/*    <Button*/}
                            {/*        variant='primary'*/}
                            {/*        onClick={onEstimateClick}*/}
                            {/*        disabled={*/}
                            {/*            isLoading || !selectedDelivs.length || !userGroups.length*/}
                            {/*        }*/}
                            {/*    >*/}
                            {/*        HC Estimate*/}
                            {/*    </Button>*/}
                            {/*)}*/}
                            {canEdit &&
                                !(isStlNotAdmin && !isStlEditable) &&
                                !isGlobalDeliverablesPage && (
                                    <ButtonDropdown
                                        items={[
                                            {
                                                text: 'Edit',
                                                id: 'edit_deliverable',
                                                disabled:
                                                    isRevisionLocked ||
                                                    selectedDelivs.length !== 1 ||
                                                    !programMetadata?.is_active ||
                                                    (isStlNotAdmin && !isStlEditable),
                                            },
                                            {
                                                text: 'Delete',
                                                id: 'delete_deliverable',
                                                disabled:
                                                    isRevisionLocked ||
                                                    selectedDelivs.length !== 1 ||
                                                    (isStlNotAdmin && !isStlEditable),
                                            },
                                            {
                                                text: 'Copy Deliverables',
                                                id: 'copy_deliverable',
                                                disabled:
                                                    isRevisionLocked ||
                                                    selectedDelivs.length === 0 ||
                                                    !canAdmin,
                                            },
                                        ]}
                                        onItemClick={({ detail }) => {
                                            if (detail.id === 'edit_deliverable') {
                                                loadSelect(selectedDelivs[0], 'deliverable')
                                                onEdit()
                                            } else if (detail.id === 'delete_deliverable') {
                                                onDelete()
                                            } else if (detail.id === 'copy_deliverable') {
                                                onCopy()
                                            }
                                        }}
                                    >
                                        Actions
                                    </ButtonDropdown>
                                )}
                            <ExportTable
                                tableData={allPageItems}
                                visibleColumns={getDeliverableExportVisibleColumns()}
                                tableColumnDef={columns}
                                fileName={`${programName}EgretDeliverables`}
                            />
                        </SpaceBetween>
                    }
                >
                    {programName} Deliverables
                </Header>
            }
        />
    )
}

export default DeliverableList
